import React from 'react';
import Layout from '../components/layout/Layout';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Markdown from "react-markdown";
import Img from "gatsby-image";
import Modal from "react-modal";

const Neuigkeiten = ({data}) => {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [imageSource, setImageSource] = React.useState('');

    const openModal = (imageSource) => {
        setImageSource(imageSource);
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }
    return (
  <Layout>
    <Helmet>
      <title>{data.strapiCompanyData.name} | {data.strapiNewsPage.seo.title}</title>
    </Helmet>
    <section className="shadow-lg bg-static-sites text-white px-5 rounded">
      <div className="container mx-auto pb-3">
        <h2 className="text-4xl">{data.strapiNewsPage.seo.title}</h2>
        <Link className="text-white hover:text-white hover:underline" to="/">zurück zur Startseite</Link>
      </div>
    </section>
    <section className="pt-5 px-5">
        <div className="container mx-auto">
        {data.allStrapiNewsItems.nodes.map(newsItem => (
            <div className="grid grid-cols-1 grid-rows-min lg:grid-cols-3 mb-8">
                <h3 className="col-span-3 row-start-1 row-end-1 text-3xl font-bold">{newsItem.title}</h3>
                <span className="col-span-3 row-start-2 row-end-2 mb-2 text-xs text-gray-600">{newsItem.publishDate}</span><br />
                <div className="col-span-3 row-start-3 lg:px-10"><Markdown children={newsItem.content} /></div>
                <div className="col-span-3 row-start-4 px-3 lg:px-10 md:px-0 lg:gap-6">
                {newsItem.images.map(image => {
                    return (
                        <button className="w-full lg:w-4/12 mb-5 px-2 lg:mb-0 news-image" onClick={() => openModal(image.localFile.childImageSharp.original.src)}>
                            <Img className="h-full rounded object-cover" fluid={image.localFile.childImageSharp.fluid} />
                        </button>
                    );
                })}
                </div>
            </div>
        ))}
        </div>
    </section>
    <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Bild des Ladens"
        className="image-modal"
    >
        <button className="button" onClick={closeModal}>X Schließen</button>
        <img className="rounded block mx-auto" alt="Bild des Ladens" src={imageSource} />
    </Modal>
  </Layout>
)};

// export page query
export const query = graphql`
query News {
  strapiCompanyData {
    name
  }
  strapiNewsPage {
    seo {
      title
      description
    }
  }
  allStrapiNewsItems(filter: {type: {eq: "news"}}, sort: {fields: publishDate, order: DESC}) {
    nodes {
      title
      publishDate(formatString: "DD.MM.YYYY")
      content
      images {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
            original {
              src
            }
          }
        }
      }
    }
  }
}
`

export default Neuigkeiten;